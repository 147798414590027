@import '../../../../scss/theme-bootstrap';

// new for 2019 Creative Refresh
.mpp-sort.mpp-sort-v2 {
  background: none;
  border-bottom: none;
  font-family: $font--futura-book;
  font-size: 15px;
  .mpp-sort {
    display: block;
    height: auto;
    &__container {
      padding: 0;
      @include breakpoint($medium-up) {
        float: none;
        display: inline-block;
        width: auto;
      }
    }
  }
  .mpp-sort__title {
    font-family: $font--futura-demi;
    line-height: 30px;
    font-size: 15px;
    @include breakpoint($medium-up) {
      border: none;
      cursor: pointer;
      font-family: $font--futura-book;
      font-size: 15px;
      position: relative;
      vertical-align: middle;
      text-transform: capitalize;
      display: flex;
      flex-flow: row;
      justify-content: center;
      &-text {
        align-items: center;
        display: flex;
        margin-right: 5px;
        opacity: 0;
      }
    }
    &--mobile {
      margin-right: 5px;
    }
  }
  .mpp-sort__header {
    display: inline-block;
    height: 15px;
    line-height: 15px;
  }
  .mpp-sort__label {
    line-height: 1;
    float: left;
  }
  .mpp-sort__section {
    font-size: 15px;
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-right: 10px;
    @include breakpoint($medium-up) {
      display: inline-block;
      padding: 0;
      margin: 0 1em;
      position: relative;
    }
  }
  .mpp-sort__options {
    display: block;
    @include breakpoint($medium-up) {
      background: $color-white;
      border: solid 1px $color-gray;
      border-top: none;
      position: absolute;
      right: 0;
      padding: 0 14px 1em 13px;
      min-width: 140px;
      top: 2.4em;
      z-index: 11;
    }
    &.active {
      display: inline-block;
    }
    &.hide-sort {
      display: block;
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
  .mpp-sort__label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 13px;
    @include breakpoint($medium-up) {
      border: solid 1px $color-mid-gray;
      min-width: 140px;
    }
  }
  .mpp-sort__arrow {
    display: none;
    &--mobile {
      display: table-cell;
    }
    @include breakpoint($medium-up) {
      height: 15px;
      line-height: 15px;
      width: 25px;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 1.2em 0;
    }
    i {
      height: 13px;
      width: 13px;
      position: relative;
      bottom: -1px;
      transition: 0.4s ease;
      float: right;
      &::before,
      &::after {
        position: absolute;
        content: '';
        transition: 0.4s ease;
        background-color: transparent;
        width: 1px;
        height: 16px;
        border-bottom: 8px solid $color-black;
        top: 2px;
        left: 0;
      }
      &::before {
        transform: rotate(-135deg);
      }
      &::after {
        position: absolute;
        transform: rotate(135deg);
      }
    }
  }
  .sort-active {
    .mpp-sort__arrow {
      > i {
        transform: translate(0, -6px);
        &::before {
          transform: rotate(-45deg);
        }
        &::after {
          transform: rotate(45deg);
        }
      }
    }
  }
  // used on mobile only - we hide for PC
  .mpp-sort__arrow--left {
    display: block;
    height: 12px;
    line-height: 12px;
    margin-top: 3px;
    position: absolute;
    left: 0;
    width: 25px;
    @include breakpoint($medium-up) {
      display: none;
    }
    i {
      @include bordered-triangle(12px, $color-white, left, $color-cr18-black);
      display: block;
      @include breakpoint($medium-up) {
        display: none;
      }
      &:before,
      &:after {
        left: 10px;
        top: 5px;
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
  }
  .mpp-sort__option {
    cursor: pointer;
    display: block;
    font-family: $font--futura-book;
    font-size: 15px;
    line-height: 1.5;
    position: relative;
    @include breakpoint($medium-up) {
      border: 0;
      padding: 0;
      white-space: nowrap;
      &:hover {
        color: $color-gray;
        opacity: 1;
      }
      &:last-child {
        padding: 0;
      }
    }
  }
  .selectBox {
    text-transform: none;
    border: none;
    height: 40px;
    line-height: 36px;
    border: solid 1px $color-light-gray;
    @include breakpoint($small-up) {
      max-width: 160px;
    }
    @include breakpoint($small-down) {
      max-width: 156px;
    }
  }
  // selectbox falling behind mpp badges text fix
  .selectbox--plain.selectBox-dropdown-menu {
    z-index: 2;
  }
  .selectBox-dropdown-menu {
    font-family: $font--futura-book;
    font-size: 15px;
    text-transform: none;
  }
  .selectBox-label {
    font-weight: normal;
  }
}
